/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "We apply an agile versioning strategy to our API. This means that when we update our API, we maintain backward compatibility for as long as possible. We increment the API version only when changes break backward compatibility."), "\n", React.createElement(_components.h2, null, "How we implement agile versioning"), "\n", React.createElement(_components.p, null, "We specify the version number of the API in the URL of the resource, directly before the category or feature name. For example, ", React.createElement(_components.code, null, "https://api.payroc.com/v1/funding-accounts"), "."), "\n", React.createElement(_components.p, null, "When we release a major update, we update the version number of the API to the next whole number."), "\n", React.createElement(Admonition, {
    type: "important"
  }, React.createElement(_components.p, null, "Use HTTPS for all requests to the Payroc API. We reject all HTTP requests, and all requests that are not properly authenticated.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
